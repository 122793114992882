import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';
import { element } from 'prop-types';

// ----------------------------------------------------------------------

// OVERVIEW
const Setting = lazy(() => import('src/pages/dashboard/setting'));
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const AdminPage = lazy(() => import('src/pages/dashboard/admin/app'));
const AdminChatPage = lazy(() => import('src/pages/dashboard/admin/chat'));
const AdminInvitePage = lazy(() => import('src/pages/dashboard/admin/invite'))
const AdminCreateProjectPage = lazy(() => import('src/pages/dashboard/admin/create-project'))
const AdminProjectInfoPage = lazy(() => import('src/pages/dashboard/admin/project-info'));
const AdminMemberInfoPage = lazy(() => import('src/pages/dashboard/admin/member-info'));

const EmployeeAppPage = lazy(() => import('src/pages/dashboard/employee/app'));
const EmployeeCreateProfilePage = lazy(() => import('src/pages/dashboard/employee/create-profile'))

const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// Client
const ClientDetailsPage = lazy(() => import('src/pages/dashboard/client/details'));
const ClientListPage = lazy(() => import('src/pages/dashboard/client/list'));
const ClientCreatePage = lazy(() => import('src/pages/dashboard/client/new'));
const ClientEditPage = lazy(() => import('src/pages/dashboard/client/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// ASSESSMENT
const AssessmentTakePage = lazy(() => import('src/pages/dashboard/assessment'));
// CASE SIMULATION
const CaseSimulationPage = lazy(() => import('src/pages/case-simulation'));
// LEARNING PATH
const LearningPathDetailPage = lazy(() => import('src/pages/dashboard/learning-path'));
const LearningPathList = lazy(() => import('src/pages/dashboard/learning'));
// NEW USER THREE MODEL CHAT PAGE
const NewUserChatPage = lazy(() => import('src/pages/dashboard/new-user-chat'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'admin',
        children: [
          {
            path: 'app',
            element: <AdminPage />,
          },
          {
            path: 'chat',
            element: <AdminChatPage />,
          },
          {
            path: 'invite',
            element: <AdminInvitePage />
          },
          {
            path: 'create-project',
            element: <AdminCreateProjectPage />
          },
          {
            path: 'project-info',
            element: <AdminProjectInfoPage />
          },
          {
            path: 'member-info',
            element: <AdminMemberInfoPage />
          }
        ]
      },
      {
        path: 'employee',
        children: [
          {
            path: "app",
            element: <EmployeeAppPage />
          },
          {
            path: 'create-profile',
            element: <EmployeeCreateProfilePage />
          }
        ]
      },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      {
        path: 'learning',
        element: <LearningPathList />,
      },
      {
        path: 'assessment',
        element: <AssessmentTakePage />,
      },
      {
        path: 'case-simulation',
        element: <CaseSimulationPage />,
      },
  
      {
        path: 'learning-path',
        children: [
          { element: <LearningPathDetailPage />, index: true },
          { path: ':id', element: <LearningPathDetailPage /> },
        ]
      },
      {
        path: 'setting',
        element: <Setting />,
      },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'client',
        children: [
          { element: <ClientListPage />, index: true },
          { path: 'list', element: <ClientListPage /> },
          { path: ':id', element: <ClientDetailsPage /> },
          { path: 'new', element: <ClientCreatePage /> },
          { path: ':id/edit', element: <ClientEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
      
      { path: 'new-user-chat', element: <NewUserChatPage />},
    ],
  },
];
